import http from "./http.js";

async function getDrafts(
    page = 1,
    pageSize = 9,
){
  const response = await http.get("shipping-drafts", {
    params: {
      page,
      pageSize,
    },
  });
  return {
    totalItems: response.data.totalItems,
    items: response.data.result,
  };
}

async function getDraft(id) {
  const response = await http.get(`shipping-drafts/${id}`);
  return response.data;
}


export { getDrafts, getDraft };