<template>
  <v-layout column>
    <template v-if="!contactDetails.length">
      <v-layout
        align-center
        justify-start
      >
        <v-text-field
          value=""
          label="Контактные данные выгрузки"
          readonly
          hide-details
          outlined
          dense
          height="40"
          class="mx-2 my-0"
        />
        <v-btn
          icon
          small
          class="ma-0"
          @click="openEditor(false, 0)"
        >
          <v-icon color="grey">
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-layout>
    </template>
    <template v-for="(contact, index) in contactDetails">
      <v-layout
        :key="index"
        align-center
        justify-start
      >
        <v-text-field
          :key="index"
          :value="contact.title"
          label="Контактные данные выгрузки"
          readonly
          hide-details
          outlined
          dense
          height="40"
          class="ma-2"
        />
        <MenuRowActions
          :list="rowActions"
          :value="contact"
          :index="index"
          :open-on-click="true"
          @add="add(contactDetails.length)"
          @edit="openEditor(contact, index)"
          @remove="remove(index)"
        />
      </v-layout>
    </template>
    <v-dialog
      :value="showEditor"
      persistent
      width="1000"
    >
      <v-card>
        <v-card-title class="pb-1">
          <span class="title">Редактирование контактных данных</span>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs6>
              <app-reference
                v-model="contactDetail.contactPerson"
                class="ma-2"
                :label="
                  'Контактное лицо выгрузки' + (isOurWarehouse ? '' : '*')
                "
                :rules="isOurWarehouse ? [] : [v => !!v || '']"
                resource="contact-persons"
                component-name="ContactPerson"
                :outline="true"
                :scroll-value="scrollValue"
                @input="changeContactPerson"
              />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="contactDetail.contactPhone"
                class="ma-2"
                :label="
                  'Контактный номер телефона (выгрузка)' +
                    (isOurWarehouse ? '' : '*')
                "
                hide-details
                :rules="isOurWarehouse ? [] : [v => !!v || '']"
                :mask="getMask('phone')"
                outlined
                dense
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            color="primary"
            @click="setContactDetails"
          >
            Ок
          </v-btn>
          <v-btn
            small
            @click="closeEditor"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import { getLoadingPersonContact } from "../../../backend/newShippingApi.js";
import MenuRowActions from "../../../components/MenuRowActions.vue";

export default {
  components: {
    MenuRowActions,
  },
  props: ["value", "scrollValue", "entityId", "isOurWarehouse"],
  data() {
    return {
      contactDetails: [],
      contactDetail: null,
      showEditor: false,
    };
  },
  computed: {
    isDisabled() {
      return !this.contactDetails[this.contactDetails.length - 1]?.contactPerson
        ?.id;
    },
    rowActions() {
      if (!this.entityId) {
        return [
          {
            title: "Добавить",
            name: "add",
            action: this.add,
            type: "icon",
            icon: "mdi-add",
            disabled: this.isDisabled,
          },
          {
            title: "Изменить",
            name: "edit",
            action: this.openEditor,
            type: "icon",
            icon: "mdi-pencil",
            disabled: false,
          },
          {
            title: "Удалить",
            name: "remove",
            action: this.remove,
            type: "icon",
            icon: "mdi-delete",
            disabled: false,
          },
        ];
      } else {
        return [
          {
            title: "Изменить",
            name: "edit",
            action: this.openEditor,
            type: "icon",
            icon: "mdi-pencil",
            disabled: false,
          },
          {
            title: "Удалить",
            name: "remove",
            action: this.remove,
            type: "icon",
            icon: "mdi-delete",
            disabled: false,
          },
        ];
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val || !val.length) {
          return (this.contactDetails = []);
        }
        this.contactDetails = val.map(v => {
          const phone = v.contactPhone
            ? this.$options.filters.phoneInternational(v.contactPhone)
            : "";
          const textDetail = v.contactPerson
            ? v.contactPhone
              ? `${v.contactPerson.title} / ${phone}`
              : v.contactPerson.title
            : "";
          return {
            id: v.id,
            title: textDetail,
            contactPerson: v.contactPerson,
            contactPhone: v.contactPhone,
          };
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.contactDetail = this.setContactDetail(false, 0);
  },
  methods: {
    async changeContactPerson(value) {
      if (!value) {
        this.contactDetail = this.setContactDetail(false, 0);
        return;
      }
      const response = await getLoadingPersonContact(value.id);
      if (!response) {
        return;
      }
      this.contactDetail = {
        id: this.contactDetail.id,
        title: "",
        contactPerson: { id: response.id, title: response.fullName },
        contactPhone: response.phones.length ? response.phones[0] : "",
        index: this.contactDetail.index,
      };
    },
    setContactDetail(value, index) {
      if (!value) {
        return {
          contactPerson: null,
          contactPhone: "",
          title: "",
          id: 0,
          index,
        };
      }
      return {
        contactPerson: value.contactPerson,
        contactPhone: value.contactPhone,
        title: value.title,
        id: value.id,
        index,
      };
    },
    add(index) {
      this.contactDetail = this.setContactDetail(false, index);
      this.contactDetails.push(this.contactDetail);
      this.openEditor(this.contactDetail, index);
    },
    remove(index) {
      this.contactDetails.splice(index, 1);
      this.$emit("change", this.contactDetails);
    },
    setContactDetails() {
      this.showEditor = false;
      if (this.contactDetails.length) {
        this.contactDetails.forEach((c, index) => {
          if (index === this.contactDetail.index) {
            c.contactPerson = this.contactDetail.contactPerson;
            c.contactPhone = this.contactDetail.contactPhone;
          }
        });
      } else {
        this.contactDetails.push(this.contactDetail);
      }

      this.$emit("change", this.contactDetails);
    },
    openEditor(contact, index) {
      if (contact) {
        this.contactDetail = this.setContactDetail(contact, index);
      } else {
        this.contactDetail = this.setContactDetail(false, index);
      }
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.contactDetail = this.setContactDetail(false, 0);
    },
    getMask(controlType) {
      switch (controlType) {
        case "phone":
          return "+##(###)###-##-##";
        case "number":
          return "##########";
        default:
          return "";
      }
    },
  },
};
</script>
