<template>
  <v-layout>
    <v-layout
      v-if="packageItems.length === 0"
      row
    >
      <h2 class="mx-2 ml-4 pt-2 stepper-title primary--text">
        Добавить данные по грузу
      </h2>
      <v-btn
        small
        class="mt-2 plus-button mb-2"
        :disabled="readonly"
        @click="addPackage"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-layout>
    <v-layout
      v-else
      column
    >
      <v-flex
        v-for="(packageItem, index) in packageItems"
        :key="index"
      >
        <v-layout align-center>
          <app-reference
            v-model="packageItem.packageType"
            label="Тип упаковки*"
            :rules="[v => !!v || '']"
            resource="package-types"
            component-name="PackageType"
            required
            :error="checkMissingElement(packageItem.packageType, true)"
            :readonly="readonly"
            :disable-add="true"
            :scroll-value="scrollValue"
            class="ma-2"
            @input="changePackageType(index, packageItem.packageType)"
          />
          <DecimalEditor
            v-model="packageItem.length"
            label="Длина"
            :scale="2"
            :readonly="readonly"
            :disabled="
              packageItem.packageType &&
                packageItem.packageType.id == packageTypes.PalletEuro.id
            "
            class="ma-2"
            @input="changePackage"
          />
          <DecimalEditor
            v-model="packageItem.width"
            label="Ширина"
            :scale="2"
            :readonly="readonly"
            :disabled="
              packageItem.packageType &&
                packageItem.packageType.id == packageTypes.PalletEuro.id
            "
            class="ma-2"
            @input="changePackage"
          />
          <DecimalEditor
            v-model="packageItem.height"
            label="Высота"
            :scale="2"
            :readonly="readonly"
            :disabled="
              packageItem.packageType &&
                packageItem.packageType.id == packageTypes.Roll.id
            "
            class="ma-2"
            @input="changePackage"
          />
          <DecimalEditor
            v-model="packageItem.quantity"
            label="Количество*"
            :scale="2"
            :rules="[v => !!v || '']"
            required
            :error="checkMissingElement(packageItem.packageType, true)"
            :readonly="readonly"
            class="ma-2"
            @input="changePackage"
          />
          <MenuRowActions
            v-if="!readonly"
            :list="rowActions"
            :value="packageItem"
            :index="index"
            @add="addPackage"
            @remove="removePackage(index)"
          />
        </v-layout>
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<script>
import MenuRowActions from "../../../components/MenuRowActions.vue";
import { getPackageType } from "../../../backend/newShippingApi.js";
import { packages } from "../../../enums/packages-types.js";

export default {
  components: {
    MenuRowActions,
  },
  props: ["value", "indicateOfMissingValue", "readonly", "scrollValue"],
  data() {
    return {
      addPackagingForm: false,
      packageItems: [],
    };
  },
  computed: {
    rowActions() {
      return [
        {
          title: "Добавить",
          name: "add",
          action: this.addPackage,
          type: "icon",
          icon: "mdi-plus",
          disabled: !this.packageItems.find(c => c.packageType && c.quantity),
        },
        {
          title: "Удалить",
          name: "remove",
          action: this.removePackage,
          type: "icon",
          icon: "mdi-delete",
        },
      ];
    },
    packageTypes() {
      return packages;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val || !val.length) {
          return;
        }
        this.packageItems = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addPackage() {
      this.packageItems.push({
        packageType: null,
        quantity: 0,
        length: 0,
        width: 0,
        height: 0,
      });
    },
    removePackage(index) {
      this.packageItems.splice(index, 1);
    },
    async changePackageType(index, value) {
      if (!value) {
        this.packageItems.forEach((p, ind) => {
          if (ind === index) {
            p.packageType = null;
            p.width = 0;
            p.length = 0;
            p.height = 0;
            p.quantity = 0;
          }
        });
        return;
      }
      const packageType = await getPackageType(value.id);
      this.packageItems.forEach((p, ind) => {
        if (ind === index) {
          p.packageType = packageType.packageType;
          p.width =
            packageType.packageType?.id == this.packageTypes.PalletEuro.id
              ? packageType.width
              : 0;
          p.length =
            packageType.packageType?.id == this.packageTypes.PalletEuro.id
              ? packageType.length
              : 0;
          p.height = 0;
          p.quantity = 0;
        } else {
          p;
        }
      });
      this.changePackage();
    },
    changePackage() {
      this.$emit("change", this.packageItems);
    },
    checkMissingElement(value, required) {
      if (!this.indicateOfMissingValue) {
        return;
      }
      if (!value && !required) {
        const color = "#ef5350";
        return color;
      }
      if (!value && required) {
        return true;
      }
    },
    changePackages(value) {
      this.entity.packages = value;
      if (
        this.entity.packages.find(
          i => i.width > 2.4 || i.height > 2.4 || i.length > 2.4,
        )
      ) {
        this.entity.oversized = true;
      } else {
        this.entity.oversized = false;
      }
      this.handleEntityChange();
    },
  },
};
</script>
