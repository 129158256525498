<template>
  <v-layout column>
    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs6>
        <app-reference
          v-model="entity.country"
          :outline="true"
          class="mx-2 my-0"
          label="Страна отправления*"
          :rules="[v => !!v || '']"
          resource="countries"
          component-name="Country"
          :error="checkMissingElement(entity.country, true)"
          :scroll-value="scrollValue"
          :disabled="disabled"
          parent-filter="factoryId"
          :parent-id="entity.senderFactory ? entity.senderFactory.id : 0"
          @input="changeCountry"
        />
      </v-flex>
      <v-flex xs6>
        <app-tiny-reference
          :id="clientId"
          ref="client-alias"
          v-model="entity.clientAlias"
          :outline="true"
          class="mx-2 my-0"
          label="Клиент по документам"
          resource="clients"
          info="aliases"
          :without-length-lock="true"
          :scroll-value="scrollValue"
          @input="handleEntityChange"
        >
          <template v-slot:append-content="slotProps">
            <v-layout align-end>
              <v-text-field
                v-model="newClientAlias"
                outlined
                class="px-1 pt-0 mt-0 text-field-size"
                placeholder="Добавить название"
                hide-details
                dense
              />
              <v-divider horisontal />
              <v-spacer />
              <v-btn
                small
                :disabled="newClientAlias.length < 1"
                class="ma-0 ml-0 mr-1 plus-button"
                @click="saveNewClientAlias(slotProps.items)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-layout>
          </template>
        </app-tiny-reference>
      </v-flex>
    </v-layout>
    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs12>
        <v-textarea
          slot="activator"
          v-model="entity.loadingNumber"
          label="Референс погрузки"
          outlined
          dense
          class="mx-2 my-0"
          scroll-value
          rows="1"
          auto-grow
          hide-details
          @input="handleEntityChange"
        />
      </v-flex>
    </v-layout>

    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs6>
        <LoadingPlaceReference
          v-model="entity.loadingPlace"
          label="Адрес места погрузки*"
          :entity="entity.loadingPlace"
          :factory-id="entity.senderFactory ? entity.senderFactory.id : 0"
          class="mx-2 my-0"
          :error="checkMissingElement(entity.loadingAddress, true)"
          :scroll-value="scrollValue"
          :disabled="disabled"
          :loading-items="loadingItems"
          @input="loadingPlaceChanged"
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="entity.loadingPlaceTimetable"
          outlined
          dense
          class="mx-2 my-0"
          label="Время работы места погрузки"
          hide-details
        />
      </v-flex>
    </v-layout>
    <LoadingContacts
      :value="contacts"
      :sender-factory="entity.senderFactory"
      :loading-place="entity.loadingPlace"
      :scroll-value="scrollValue"
      :entity-id="entity.id ? entity.id : 0"
      sender-type="FACTORY"
      @change="contactsLoadingChanged"
    />
  </v-layout>
</template>

<script>
import LoadingPlaceReference from "./LoadingPlaceReference.vue";
import LoadingContacts from "./LoadingContacts.vue";
import * as newShippingApi from "../../../backend/newShippingApi.js";
import auth from "../../../auth.js";

export default {
  components: {
    LoadingPlaceReference,
    LoadingContacts,
  },
  props: [
    "entity",
    "senderFactory",
    "isHighlightLabel",
    "loading",
    "scrollValue",
    "changedSenderType",
    "disabled",
  ],
  data() {
    return {
      newClientAlias: "",
      waitData: false,
      contacts: [],
      loadingItems: false,
    };
  },
  computed: {
    clientId() {
      const user = auth.getUser();
      return user.clientId;
    },
  },
  watch: {
    senderFactory: {
      handler(value) {
        if (value && !this.waitData) {
          this.factoryChanged();
        } else {
          this.entity.country = null;
          this.clearSender();
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.entity.id && this.changedSenderType) {
      this.factoryChanged();
      return;
    }
    this.waitData = this.loading ? this.loading : false;

    if (!!this.entity.contactsLoading && this.entity.contactsLoading.length > 0) {
      this.contacts = this.entity.contactsLoading;
    }
  },
  methods: {
    handleEntityChange() {
      this.$emit("handleEntityChange");
    },
    clearSender() {
      if (!this.entity.loadingPlace) {
        return;
      }
      this.entity.loadingPlace = null;
      this.entity.loadingAddress = null;
      this.entity.contactsLoading = [];
      this.entity.loadingPlaceTimetable = "";
      this.contacts = [];
    },
    async saveNewClientAlias(items) {
      await newShippingApi.updateNewClientAlias(this.clientId, this.newClientAlias);
      this.entity.clientAlias = this.newClientAlias;
      items.push({ value: this.newClientAlias, text: this.newClientAlias });
      this.$refs["client-alias"].$children[0].blur();
      this.newClientAlias = "";
      this.handleEntityChange();
    },
    async factoryChanged() {
      if (!this.entity.senderFactory) {
        return;
      }

      this.entity.loadingPlace = null;
      this.entity.contactsLoading = [];
      this.entity.loadingPlaceTimetable = "";
      this.loadingItems = true;
      const response = await newShippingApi.getFactory(
        this.entity.senderFactory.id,
      );
      if (response && response.loadingPlaces.length >= 1) {
        if (response.loadingPlaces[0].address.country) {
          this.$set(this.entity, "country", {
            id: response.loadingPlaces[0].address.country.id,
            title: response.loadingPlaces[0].address.country.title,
          });
        }
      }
      if (response && response.loadingPlaces.length === 1) {
        if (this.isChangeTimetable) {
          return;
        } else {
          this.$set(
            this.entity,
            "loadingPlaceTimetable",
            response.loadingPlaces[0].timetable,
          );
        }
        if (response.loadingPlaces[0].address) {
          this.$set(this.entity, "loadingPlace", {
            id: response.loadingPlaces[0].id,
            title: response.loadingPlaces[0].address.title,
          });
          this.$set(this.entity, "loadingAddress", {
            id: response.loadingPlaces[0].address.id,
            title: response.loadingPlaces[0].address.title,
          });
        }
        if (response.loadingPlaces[0].contactPersons.length === 1) {
          this.$set(this.entity, "contactsLoading", [
            {
              id: 0,
              contactPerson: {
                id: response.loadingPlaces[0].contactPersons[0].id,
                title: response.loadingPlaces[0].contactPersons[0].fullName,
              },
              contactPhone: response.loadingPlaces[0].contactPersons[0].phones.join(
                "; ",
              ),
              contactEmail: response.loadingPlaces[0].contactPersons[0].emails.join(
                "; ",
              ),
            },
          ]);
        }
      }
      this.loadingItems = false;
    },
    async loadingPlaceChanged(value) {
      if (this.waitData) {
        this.waitData = false;
        return;
      }
      this.entity.loadingPlaceTimetable = "";
      this.entity.contactsLoading = [];

      if (!this.entity.senderFactory) {
        return;
      }

      const id = value ? value.id : this.entity.loadingPlace?.id;
      if (!id) {
        return;
      }
      this.loadingItems = true;
      const response = await newShippingApi.getSenderFactoryLoadingPlace(
        this.entity.senderFactory.id,
        id,
      );
      if (response) {
        this.$set(this.entity, "loadingAddress", {
          id: response.address.id,
          title: response.address.title,
        });
        if (response.timetable) {
          this.$set(this.entity, "loadingPlaceTimetable", response.timetable);
        }
        if (response.contactPersons.length === 1) {
          this.$set(this.entity, "contactsLoading", [
            {
              id: 0,
              contactPerson: {
                id: response.contactPersons[0].id,
                title: response.contactPersons[0].fullName,
              },
              contactPhone: response.contactPersons[0].phones.map(p =>
                this.$options.filters.phoneInternational(p),
              ).join("; "),
              contactEmail: response.contactPersons[0].emails.join("; "),
            },
          ]);
        } else {
          this.$set(this.entity, "contactsLoading", []);
        }
      }
      this.loadingItems = false;
    },
    contactsLoadingChanged(contactDetails) {
      if (this.waitData) {
        this.waitData = false;
        return;
      }

      this.$set(this.entity, "contactsLoading", contactDetails);
      this.contacts = contactDetails;
    },
    checkMissingElement(value, required) {
      if (!this.isHighlightLabel) {
        return;
      }
      if (!value && !required) {
        return "label-color";
      }
      if (!value && required) {
        return true;
      }
    },
    changeCountry(value) {
      if (!value) {
        this.clearSender();
      } else {
        this.entity.country = value;
      }
    },
  },
};
</script>
