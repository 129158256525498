<template>
  <v-select
    v-model="selected"
    menu-props="offsetY"
    :items="items"
    item-text="title"
    item-value="id"
    :label="title"
    :background-color="backgroundColor"
    return-object
    height="25"
    hide-details
    hide-selected
    :readonly="readonly"
    @change="change"
  >
    <template v-slot:selection="{ item }">
      <span class="pl-1">{{ item.title }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  props: ["title", "value", "items", "colors", "readonly", "scrollValue"],
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    backgroundColor() {
      if (!this.colors || this.colors.length === 0) {
        return "white";
      } else {
        const selectedIndex = this.items.indexOf(
          this.items.find(i => i.id === this.selected.id),
        );
        if (selectedIndex > this.colors.length - 1) {
          return "white";
        }
        return this.colors[selectedIndex] + " lighten-4";
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val) {
          this.selected = this.items.find(i => i.id === 0);
          return;
        }
        this.selected = this.items.find(i => i.id === val);
      },
      immediate: true,
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      closeActivList(this.$children[0]);

      function closeActivList(value) {
        if (value.isActive) {
          value.isActive = false;
        }
        if (value.$children) {
          value.$children.forEach(v => closeActivList(v));
        }
      }
    },
  },
  methods: {
    change() {
      this.$emit("input", this.selected.id);
    },
  },
};
</script>
