<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    clearable
    :label="label"
    return-object
    dense
    hide-details
    hide-selected
    outlined
    no-filter
    no-data-text="Данные не выбраны"
    :class="[{'red lighten-4':selected && selected.isRemoved}, labelColor]"
    :menu-props="menuPropsClose"
    prepend-inner-icon="mdi-folder-outline"
    @change="handleItemChange"
    @click="closeMenuPropsOnClick(false)"
  >
    <template v-slot:append>
      <AddReferenceBtn @click="createNew" />
    </template>
  </v-autocomplete>
</template>
<script>
import AddReferenceBtn from "../../../components/AddReferenceBtn.vue";
import CloseDropdownList from "../../../mixins/close-dropdown-list.js";
import { NestedEditorsEventBus } from "../../../components/nested-editors/nested-editors-event-bus.js";
import * as newShippingApi from "../../../backend/newShippingApi.js";
import { getReference } from "../../../backend/referenceApi";

export default {
  components: { AddReferenceBtn },
  mixins: [CloseDropdownList],
  props: [
    "value",
    "warehouseId",
    "labelColor",
    "scrollValue",
  ],
  data() {
    return {
      selected: null,
      search: null,
      items: [],
      loading: false,
      label: "Контактное лицо склада",
      resource: "contact-persons",
      componentName: "ContactPerson",
    };
  },
  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.selected = null;
          this.search = null;
          this.items = [];
          return;
        }
        if (this.selected && this.value.id === this.selected.value) {
          return;
        }
        const selectedItem = this.items.find((i) => i.value === this.value.id);
        if (selectedItem) {
          this.selected = selectedItem;
        } else {
          this.setSelectedElement(this.value);
        }
      },
      deep: true,
    },
    search() {
      if (!this.search) {
        this.selected = null;
        this.getData();
        return;
      }

      if (this.loading) {
        return;
      }

      if (this.selected && this.selected.text === this.search) {
        return;
      }

      this.loading = true;
      setTimeout(() => {
        if (!this.search) {
          this.selected = null;
          this.items = [];
          this.loading = false;
          return;
        }
        this.getData();
        this.loading = false;
      }, 500);
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      closeActivList(this.$children[0]);

      function closeActivList(value) {
        if (value.isActive) {
          value.isActive = false;
        }
        if (value.$children) {
          value.$children.forEach((v) => closeActivList(v));
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.setSelectedElement(this.value);
    }
    NestedEditorsEventBus.$on("create-" + this._uid, (createdId) => {
      this.getWarehouseReference(createdId);
      this.showInfo("Контактное лицо добавлено");
      this.loading = false;
      this.handleItemChange();
    });
  },
  mounted() {
    this.$watch(
      () => {
        return this.$children[0].$data.isMenuActive;
      },
      (newVal) => {
        if (newVal && this.warehouseId) {
          this.getData();
        } else {
          this.clear();
        }
      },
    );
  },
  methods: {
    handleItemChange() {
      this.$emit(
        "input",
        this.selected
          ? { id: this.selected.value, title: this.selected.text }
          : null,
      );
    },
    async getData() {
      if (!this.warehouseId) {
        return;
      }
      this.loading = true;
      this.items = await newShippingApi.getWarehouseContactPerson(
        this.warehouseId,
      );
      this.loading = false;
    },
    clear() {
      this.items = [];
      if (this.selected) {
        this.items.push(this.selected);
      }
    },
    createNew() {
      if (!this.warehouseId) {
        this.showInfo(
          "Заполните все обязательные поля в Шаге 1 для корректного создания нового контактного лица",
        );
        return;
      }
      this.closeMenuPropsOnClick(true);
      this.$emit("click");
      NestedEditorsEventBus.$emit("open", {
        title: this.label,
        uid: this._uid,
        component: this.componentName,
      });
    },
    setSelectedElement(value) {
      const newItem = {
        text: value.title,
        value: value.id,
      };
      this.items = [];
      this.items.push(newItem);
      this.selected = newItem;
      this.handleItemChange();
    },
    async getWarehouseReference(id) {
      const contactPerson = await getReference(this.resource, id);
      this.setSelectedElement(contactPerson);
      await newShippingApi.createWarehouseContactPersonReference(
        this.warehouseId,
        contactPerson.id,
      );
    },
  },
};
</script>

<style >
.label-color {
  color: #ef5350;
}
</style>
