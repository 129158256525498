<template>
  <div class="text-xs-center">
    <v-menu
      bottom
      offset-y
      :open-on-hover="!openOnClick"
      nudge-left="60"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          class="ma-0"
          v-on="on"
        >
          <v-icon color="grey">
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <div
          v-for="(item, i) in list"
          :key="i"
        >
          <v-list-item
            class="grey--text text--darken-2"
            :disabled="updateDisabled(item)"
            @click="updateDisabled(item) ? null : apply(item.name)"
          >
            <v-list-item-title class="list-line">
              <v-icon
                v-if="item.type === 'icon'"
                color="grey darken-2"
                :disabled="updateDisabled(item)"
                small
              >
                {{ item.icon }}
              </v-icon>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-divider
            v-if="list.indexOf(item) !== list.length - 1"
            horizontal
          />
        </div>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: ["value", "list", "openOnClick", "index", "lastIndex"],
  methods: {
    apply(value) {
      this.$emit(value, this.value);
    },
    updateDisabled(item) {
      return (
        item.disabled ||
        (item.icon === "mdi-arrow-up" && this.index === 0) ||
        (item.icon === "mdi-arrow-down" && this.index === this.lastIndex)
      );
    },
  },
};
</script>
<style>
.list-line {
  height: 28px;
  cursor: pointer;
}
</style>
