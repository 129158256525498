<template>
  <v-layout
    align-center
    justify-center
  >
    <v-flex
      xs12
      sm11
      md10
      lg9
    >
      <v-layout
        align-center
      >
        <v-flex
          xs1
          class="text-xs-center"
        >
          <v-btn
            text
            large
            icon
            @click="stepper = prevStep"
          >
            <v-icon
              x-large
              color="primary"
            >
              mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs10>
          <v-layout>
            <h1 class="headline mb-2">
              Новая поставка
            </h1>
          </v-layout>
          <v-card
            class="elevation-0"
          >
            <v-card-text
              class="pa-0"
            >
              <v-form
                v-model="isValid"
              >
                <v-stepper
                  v-model="stepper"
                  class="pa-0 elevation-0"
                >
                  <v-stepper-header>
                    <v-stepper-step
                      :complete="stepper > 1"
                      step="1"
                      editable
                      class="px-2 py-0"
                      @click="stepper = 1"
                    >
                      {{ stepTitles[0] }}
                    </v-stepper-step>

                    <v-divider class="ma-0" />

                    <v-stepper-step
                      :complete="stepper > 2"
                      step="2"
                      editable
                      class="px-2 py-0"
                      @click="stepper = 2"
                    >
                      {{ stepTitles[1] }}
                    </v-stepper-step>

                    <v-divider class="ma-0" />

                    <v-stepper-step
                      :complete="stepper > 3"
                      step="3"
                      editable
                      class="px-2 py-0"
                      @click="stepper = 3"
                    >
                      {{ stepTitles[2] }}
                    </v-stepper-step>

                    <v-divider class="ma-0" />

                    <v-stepper-step
                      :complete="stepper > 4"
                      step="4"
                      editable
                      class="px-2 py-0"
                      @click="stepper = 4"
                    >
                      {{ stepTitles[3] }}
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items
                    id="scroll-target"
                    class="mx-1 mt-4 mb-3 scroll-y"
                    :style="{
                      height: `${blockHeight + 'px'}`,
                      'overflow-y': 'auto'
                    }"
                  >
                    <v-stepper-content
                      step="1"
                      class="pa-0"
                    >
                      <h2 class="mx-2 stepper-title primary--text pb-2">
                        Первоначальное местонахождение груза
                      </h2>
                      <v-layout column>
                        <v-flex xs6>
                          <app-reference
                            v-model="entity.senderFactory"
                            class="mx-2 my-0"
                            label="Продавец*"
                            resource="factories"
                            component-name="Factory"
                            parent-filter="countryId"
                            :parent-id="entity.country ? entity.country.id : 0"
                            :without-parent-lock="true"
                            :rules="[v => !!v || '']"
                            @handleEntityChange="handleEntityChange"
                          />
                        </v-flex>
                        <v-flex>
                          <v-radio-group
                            v-model="senderType"
                            hide-details
                            row
                            height="25"
                            class="my-3 mx-3"
                          >
                            <h2 class="body-1 mr-2">
                              Место отгрузки:
                            </h2>
                            <v-radio
                              v-for="(item, value) in senderTypeItems"
                              :key="value"
                              :label="item.text"
                              :value="item.value"
                            />
                          </v-radio-group>
                        </v-flex>
                        <SenderFactory
                          v-if="senderType === unloadingPlaceTypes.FACTORY"
                          :entity="entity"
                          :sender-factory="entity.senderFactory"
                          :loading="id && entity.loadingPlace ? true : false"
                          @handleEntityChange="handleEntityChange"
                        />
                        <SenderWarehouse
                          v-if="
                            senderType === unloadingPlaceTypes.GROUPEDWAREHOUSE
                          "
                          :entity="entity"
                          warehouse-mode="groupedWarehouse"
                          @handleEntityChange="handleEntityChange"
                        />
                        <SenderAddress
                          v-if="senderType === unloadingPlaceTypes.ADDRESS"
                          :entity="entity"
                          @handleEntityChange="handleEntityChange"
                        />
                        <SenderWarehouse
                          v-if="
                            senderType === unloadingPlaceTypes.OTHERWAREHOUSE
                          "
                          :entity="entity"
                          warehouse-mode="otherWarehouse"
                          @handleEntityChange="handleEntityChange"
                        />
                      </v-layout>
                    </v-stepper-content>

                    <v-stepper-content
                      step="2"
                      class="pa-0"
                    >
                      <h2 class="mx-2 stepper-title primary--text">
                        Характеристики товара
                      </h2>
                      <v-layout>
                        <v-flex>
                          <v-layout column>
                            <v-layout column>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex
                                  xs4
                                  class="ml-2"
                                >
                                  <app-date-picker
                                    v-model="entity.readyDate"
                                    class="mx-2 my-0"
                                    label="Дата готовности к отгрузке*"
                                    :rules="[v => !!v || '']"
                                    dense
                                    @input="checkValidWantedDate"
                                  />
                                </v-flex>
                                <v-flex
                                  xs4
                                  class="ml-2 mr-2"
                                >
                                  <DateRangePicker
                                    v-model="wantedDates"
                                    label="Желаемая дата доставки к месту выгрузки*"
                                    class="mx-2 my-0"
                                    :without-initial-data="true"
                                    :min="minDate"
                                    :clearable="true"
                                    @input="checkValidWantedDate"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <app-date-picker
                                    v-model="entity.factoryClosingDate"
                                    class="mx-2 my-0"
                                    label="Дата закрытия фабрики"
                                    :clearable="true"
                                    @input="handleEntityChange"
                                  />
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex xs4>
                                  <v-text-field
                                    v-model="entity.internalRequestNumber"
                                    outlined
                                    class="mx-2 my-0"
                                    label="Внутренний номер заявки у клиента, если он использует собственную нумерацию заявок"
                                    hide-details
                                    dense
                                    @input="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <v-text-field
                                    v-model="entity.commentForShipping"
                                    outlined
                                    class="mx-2 my-0"
                                    label="Комментарии к поставке"
                                    hide-details
                                    dense
                                  />
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="entity.productDescription"
                                    outlined
                                    class="mx-2"
                                    label="Описание товара"
                                    hide-details
                                    dense
                                    @input="handleEntityChange"
                                  />
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex>
                                  <DecimalEditor
                                    v-model="entity.weight"
                                    class="mx-2"
                                    label="Вес брутто, кг*"
                                    :rules="[v => !!v || '']"
                                    :scale="3"
                                  />
                                </v-flex>
                                <v-flex>
                                  <DecimalEditor
                                    v-model="entity.volume"
                                    class="mx-2"
                                    label="Объем, м3*"
                                    :rules="[v => !!v || '']"
                                    :scale="3"
                                    :available-zero="true"
                                  />
                                </v-flex>
                                <v-flex>
                                  <DecimalEditor
                                    v-model="entity.invoiceSum"
                                    class="mx-2"
                                    label="Стоимость партии груза*"
                                    :scale="2"
                                  />
                                </v-flex>
                                <v-flex>
                                  <app-reference
                                    v-model="entity.currency"
                                    class="mx-2"
                                    label="Валюта*"
                                    :rules="[v => !!v || '']"
                                    resource="currencies"
                                    component-name="Currency"
                                  />
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex xs9>
                                  <EnumSelect
                                    v-model="entity.needEx"
                                    :without-selected="false"
                                    :return-object="true"
                                    label="Нужна ли Ex"
                                    resource="need-ex-types"
                                    class="mx-2"
                                    @input="handleEntityChange"
                                  />
                                </v-flex>
                                <!-- Доп инфо о товаре -->
                                <v-flex xs3>
                                  <v-btn
                                    small
                                    class="mb-0 mt-3"
                                    @click="attachmentDialog = true"
                                  >
                                    {{
                                      `Документы (${newAttachments.length +
                                        newAttachmentsForExport.length})`
                                    }}
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex xs4>
                                  <LabelSelectSimple
                                    v-model="entity.parseByOrders"
                                    class="mx-2"
                                    title="Разобрать по ордерам"
                                    @change="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <LabelSelectSimple
                                    v-model="entity.isPayed"
                                    class="mx-2"
                                    title="Груз оплачен"
                                    @change="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <LabelSelectComplex
                                    v-model="entity.urgency"
                                    class="mx-2"
                                    title="Срочность"
                                    :items="[
                                      { id: 0, title: 'Стандартная' },
                                      { id: 1, title: 'Срочная' }
                                    ]"
                                    :colors="['green', 'red']"
                                    @change="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <LabelSelectSimple
                                        v-model="entity.oversized"
                                        class="mx-2"
                                        title="Негабарит"
                                        true-color="red"
                                        false-color="green"
                                        :readonly="true"
                                        @change="handleEntityChange"
                                        v-on="on"
                                      />
                                    </template>
                                    <span>Сторона больше 2,4 м</span>
                                  </v-tooltip>
                                </v-flex>
                              </v-layout>
                              <v-layout
                                column
                                class="my-2"
                              >
                                <v-flex>
                                  <Packages
                                    v-model="entity.packages"
                                    :scroll-value="scrollValue"
                                    @change="changePackages"
                                  />
                                </v-flex>
                                <v-flex>
                                  <v-textarea
                                    v-model="entity.cargoComment"
                                    outlined
                                    dense
                                    class="ma-2 mt-3"
                                    label="Комментарии к размерам и кол-ву грузовых мест"
                                    rows="1"
                                    auto-grow
                                    hide-details
                                  />
                                </v-flex>
                              </v-layout>
                            </v-layout>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                      <v-layout style="height:300px" />
                    </v-stepper-content>

                    <v-stepper-content
                      step="3"
                      class="pa-0"
                    >
                      <h2 class="mx-2 stepper-title primary--text">
                        Адрес доставки товара
                      </h2>
                      <v-layout>
                        <v-flex>
                          <v-layout column>
                            <v-layout
                              align-end
                              class="my-2"
                            >
                              <v-flex>
                                <EnumSelect
                                  v-model="entity.shippingType"
                                  :rules="[v => !!v || v == 0 || '']"
                                  :without-selected="false"
                                  :return-object="true"
                                  :clearable="false"
                                  label="Тип поставки*"
                                  resource="delivery-types"
                                  class="mx-2 my-0"
                                  @input="shippingTypeChanged"
                                />
                              </v-flex>
                              <v-flex>
                                <app-reference
                                  v-model="entity.destinationCountry"
                                  class="mx-2 my-0"
                                  label="Страна получателя по докам фабрики/отправителя*"
                                  :rules="[v => !!v || '']"
                                  resource="countries/ukraine-first"
                                  component-name="Country"
                                  @input="shippingTypeChanged"
                                />
                              </v-flex>
                              <v-flex>
                                <CountryFilteringReference
                                  v-model="entity.unloadingCountry"
                                  :without-ukraine="countryWithoutUkraine"
                                  :clearable="true"
                                  label="Страна выгрузки*"
                                  :disabled="disableUnloadingCountry"
                                  resource="countries"
                                  component-name="Country"
                                  class="mx-2 my-0"
                                  @input="unloadingCountryChanged"
                                />
                              </v-flex>
                            </v-layout>
                            <v-layout
                              align-end
                              class="my-2"
                            >
                              <v-flex>
                                <app-reference
                                  v-model="entity.unloadingCity"
                                  class="mx-2 my-0"
                                  label="Город выгрузки*"
                                  :rules="[v => !!v || '']"
                                  resource="cities"
                                  parent-filter="countryId"
                                  :parent-id="
                                    entity.unloadingCountry
                                      ? entity.unloadingCountry.id
                                      : 0"
                                  component-name="City"
                                  @input="entity.unloadingAddress = ''"
                                />
                              </v-flex>
                              <v-flex>
                                <app-reference
                                  v-model="entity.unloadingAddress"
                                  class="mx-2 my-0"
                                  label="Адрес выгрузки*"
                                  :rules="[v => !!v || '']"
                                  resource="addresses"
                                  parent-filter="cityId"
                                  :parent-id="
                                    entity.unloadingCity
                                      ? entity.unloadingCity.id
                                      : 0
                                  "
                                  component-name="Address"
                                  @input="unloadingAddressChanged"
                                />
                              </v-flex>
                            </v-layout>
                            <UnloadingContacts
                              :value="entity.contactsUnloading"
                              :scroll-value="scrollValue"
                              :entity-id="entity.id"
                              :is-our-warehouse="isOurWarehouse"
                              @change="unloadingContactsChanged"
                            />
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-stepper-content>

                    <v-stepper-content
                      step="4"
                      class="pa-0"
                    >
                      <v-layout
                        v-scroll:#scroll-target="onScroll"
                        column
                        style="height: auto"
                      >
                        <v-layout
                          align-end
                          class="mt-3"
                        >
                          <h2 class="mx-2 stepper-title primary--text pb-2">
                            Первоначальное местонахождение груза
                          </h2>
                          <v-btn
                            icon
                            class="my-0"
                          >
                            <v-icon
                              color="grey"
                              @click="stepper = 1"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </v-layout>
                        <v-layout column>
                          <v-flex xs6>
                            <app-reference
                              v-model="entity.senderFactory"
                              class="mx-2 my-0"
                              label="Продавец*"
                              resource="factories"
                              component-name="Factory"
                              parent-filter="countryId"
                              :parent-id="
                                entity.country ? entity.country.id : 0
                              "
                              :without-parent-lock="true"
                              :rules="[v => !!v || '']"
                              :error="
                                checkMissingElement(entity.senderFactory, true)
                              "
                              :scroll-value="scrollValue"
                              @handleEntityChange="handleEntityChange"
                            />
                          </v-flex>
                          <v-flex>
                            <v-radio-group
                              v-model="senderType"
                              hide-details
                              row
                              height="25"
                              class="my-3 mx-3"
                            >
                              <h2 class="body-1 mr-2">
                                Место отгрузки:
                              </h2>
                              <v-radio
                                v-for="(item, value) in senderTypeItems"
                                :key="value"
                                :label="item.text"
                                :value="item.value"
                              />
                            </v-radio-group>
                          </v-flex>

                          <SenderFactory
                            v-if="senderType === unloadingPlaceTypes.FACTORY"
                            :entity="entity"
                            :sender-factory="entity.senderFactory"
                            :is-highlight-label="true"
                            :loading="id && entity.loadingPlace ? true : false"
                            @handleEntityChange="handleEntityChange"
                          />

                          <SenderWarehouse
                            v-if="
                              senderType ===
                                unloadingPlaceTypes.GROUPEDWAREHOUSE
                            "
                            :entity="entity"
                            :is-highlight-label="true"
                            warehouse-mode="groupedWarehouse"
                            @handleEntityChange="handleEntityChange"
                          />

                          <SenderAddress
                            v-if="senderType === unloadingPlaceTypes.ADDRESS"
                            :entity="entity"
                            :is-highlight-label="true"
                            @handleEntityChange="handleEntityChange"
                          />

                          <SenderWarehouse
                            v-if="
                              senderType === unloadingPlaceTypes.OTHERWAREHOUSE
                            "
                            :entity="entity"
                            :is-highlight-label="true"
                            warehouse-mode="otherWarehouse"
                            @handleEntityChange="handleEntityChange"
                          />
                        </v-layout>

                        <v-layout
                          align-end
                          class="mt-3"
                        >
                          <h2 class="mx-2 stepper-title primary--text pb-2">
                            Характеристики товара
                          </h2>
                          <v-btn
                            icon
                            class="my-0"
                          >
                            <v-icon
                              color="grey"
                              @click="stepper = 2"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </v-layout>
                        <v-layout>
                          <v-flex>
                            <v-layout column>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex
                                  xs4
                                  class="ml-2"
                                >
                                  <app-date-picker
                                    v-model="entity.readyDate"
                                    class="mx-2 my-0"
                                    label="Дата готовности к отгрузке*"
                                    :rules="[v => !!v || '']"
                                    dense
                                    @input="checkValidWantedDate"
                                  />
                                </v-flex>
                                <v-flex
                                  xs4
                                  class="ml-2 mr-2"
                                >
                                  <DateRangePicker
                                    v-model="wantedDates"
                                    label="Желаемая дата доставки к месту выгрузки*"
                                    class="mx-2 my-0"
                                    :without-initial-data="true"
                                    :min="minDate"
                                    :clearable="true"
                                    @input="checkValidWantedDate"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <app-date-picker
                                    v-model="entity.factoryClosingDate"
                                    class="mx-2 my-0"
                                    label="Дата закрытия фабрики"
                                    :clearable="true"
                                    @input="handleEntityChange"
                                  />
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex xs4>
                                  <v-text-field
                                    v-model="entity.internalRequestNumber"
                                    outlined
                                    class="mx-2 ml-0"
                                    label="Внутренний номер заявки у клиента, если он использует собственную нумерацию заявок"
                                    hide-details
                                    dense
                                    @input="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <v-text-field
                                    v-model="entity.commentForShipping"
                                    outlined
                                    class="mx-2 my-0"
                                    label="Комментарии к поставке"
                                    hide-details
                                    dense
                                  />
                                </v-flex>
                                <v-flex>
                                  <v-text-field
                                    v-model="entity.productDescription"
                                    outlined
                                    class="mx-2"
                                    label="Описание товара"
                                    hide-details
                                    dense
                                    @input="handleEntityChange"
                                  />
                                </v-flex>
                              </v-layout>

                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex>
                                  <DecimalEditor
                                    v-model="entity.weight"
                                    class="mx-2"
                                    label="Вес брутто, кг*"
                                    :scale="3"
                                    :rules="[v => !!v || '']"
                                    :error="
                                      checkMissingElement(entity.weight, true)
                                    "
                                  />
                                </v-flex>
                                <v-flex>
                                  <DecimalEditor
                                    v-model="entity.volume"
                                    class="mx-2"
                                    label="Объем, м3*"
                                    :scale="3"
                                    :rules="[v => !!v || '']"
                                    :available-zero="true"
                                    :error="
                                      checkMissingVolume(entity.volume, true)
                                    "
                                  />
                                </v-flex>
                                <v-flex>
                                  <DecimalEditor
                                    v-model="entity.invoiceSum"
                                    class="mx-2"
                                    label="Стоимость партии груза*"
                                    :scale="2"
                                    :rules="[
                                      v =>
                                        !!v || 'Поле обязательно к заполнению'
                                    ]"
                                    :error="
                                      checkMissingElement(
                                        entity.invoiceSum,
                                        true
                                      )
                                    "
                                  />
                                </v-flex>
                                <v-flex>
                                  <app-reference
                                    v-model="entity.currency"
                                    class="mx-2"
                                    label="Валюта*"
                                    :rules="[v => !!v || '']"
                                    resource="currencies"
                                    component-name="Currency"
                                  />
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex xs9>
                                  <EnumSelect
                                    v-model="entity.needEx"
                                    :without-selected="false"
                                    :return-object="true"
                                    label="Нужна ли Ex"
                                    resource="need-ex-types"
                                    :scroll-value="scrollValue"
                                    class="mx-2"
                                    @input="handleEntityChange"
                                  />
                                </v-flex>

                                <v-flex xs3>
                                  <v-btn
                                    small
                                    class="mb-0 mt-3"
                                    @click="attachmentDialog = true"
                                  >
                                    {{
                                      `Документы (${newAttachments.length +
                                        newAttachmentsForExport.length})`
                                    }}
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex xs4>
                                  <LabelSelectSimple
                                    v-model="entity.parseByOrders"
                                    class="mx-2"
                                    title="Разобрать по ордерам"
                                    :scroll-value="scrollValue"
                                    @change="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <LabelSelectSimple
                                    v-model="entity.isPayed"
                                    class="mx-2"
                                    title="Груз оплачен"
                                    :scroll-value="scrollValue"
                                    @change="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <LabelSelectComplex
                                    v-model="entity.urgency"
                                    class="mx-2"
                                    title="Срочность"
                                    :items="[
                                      { id: 0, title: 'Стандартная' },
                                      { id: 1, title: 'Срочная' }
                                    ]"
                                    :colors="['green', 'red']"
                                    :scroll-value="scrollValue"
                                    @change="handleEntityChange"
                                  />
                                </v-flex>
                                <v-flex xs4>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <LabelSelectSimple
                                        v-model="entity.oversized"
                                        class="mx-2"
                                        title="Негабарит"
                                        true-color="red"
                                        false-color="green"
                                        :scroll-value="scrollValue"
                                        :readonly="true"
                                        @change="handleEntityChange"
                                        v-on="on"
                                      />
                                    </template>
                                    <span>Сторона больше 2,4 м</span>
                                  </v-tooltip>
                                </v-flex>
                              </v-layout>
                              <v-layout
                                column
                                class="my-2"
                              >
                                <v-flex>
                                  <Packages
                                    v-model="entity.packages"
                                    :indicate-of-missing-value="true"
                                    :scroll-value="scrollValue"
                                    @change="changePackages"
                                  />
                                </v-flex>
                                <v-flex>
                                  <v-textarea
                                    v-model="entity.cargoComment"
                                    outlined
                                    dense
                                    class="ma-2"
                                    label="Комментарии к размерам и кол-ву грузовых мест"
                                    rows="1"
                                    auto-grow
                                    hide-details
                                  />
                                </v-flex>
                              </v-layout>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <v-layout
                          align-end
                          class="mt-3"
                        >
                          <h2 class="ml-2 mt-3 stepper-title primary--text ">
                            Адрес доставки товара
                          </h2>
                          <v-btn
                            icon
                            class="my-0"
                          >
                            <v-icon
                              color="grey"
                              @click="stepper = 4"
                            >
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </v-layout>
                        <v-layout>
                          <v-flex>
                            <v-layout column>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex>
                                  <EnumSelect
                                    v-model="entity.shippingType"
                                    :rules="[v => !!v || v == 0 || '']"
                                    :without-selected="false"
                                    :return-object="true"
                                    :clearable="false"
                                    label="Тип поставки*"
                                    resource="delivery-types"
                                    :scroll-value="scrollValue"
                                    class="mx-2 my-0"
                                    @input="shippingTypeChanged"
                                  />
                                </v-flex>
                                <v-flex>
                                  <app-reference
                                    v-model="entity.destinationCountry"
                                    class="mx-2 my-0"
                                    label="Страна получателя по докам фабрики/отправителя*"
                                    :rules="[v => !!v || '']"
                                    resource="countries/ukraine-first"
                                    component-name="Country"
                                    :error="
                                      checkMissingElement(
                                        entity.destinationCountry,
                                        true
                                      )
                                    "
                                    :scroll-value="scrollValue"
                                    @input="shippingTypeChanged"
                                  />
                                </v-flex>
                                <v-flex>
                                  <CountryFilteringReference
                                    v-model="entity.unloadingCountry"
                                    :rules="[v => !!v || '']"
                                    :without-ukraine="countryWithoutUkraine"
                                    :clearable="true"
                                    label="Страна выгрузки*"
                                    :disabled="disableUnloadingCountry"
                                    resource="countries"
                                    component-name="Country"
                                    class="mx-2 my-0"
                                    :error="
                                      checkMissingElement(
                                        entity.unloadingCountry,
                                        true
                                      )
                                    "
                                    :scroll-value="scrollValue"
                                    @input="unloadingCountryChanged"
                                  />
                                </v-flex>
                              </v-layout>
                              <v-layout
                                align-end
                                class="my-2"
                              >
                                <v-flex>
                                  <app-reference
                                    v-model="entity.unloadingCity"
                                    class="mx-2 my-0"
                                    label="Город выгрузки*"
                                    :rules="[v => !!v || '']"
                                    :height-field="44"
                                    resource="cities"
                                    parent-filter="countryId"
                                    :parent-id="
                                      entity.unloadingCountry
                                        ? entity.unloadingCountry.id
                                        : 0
                                    "
                                    component-name="City"
                                    :error="
                                      checkMissingElement(
                                        entity.unloadingCity,
                                        true
                                      )
                                    "
                                    :scroll-value="scrollValue"
                                    @input="entity.unloadingAddress = ''"
                                  />
                                </v-flex>
                                <v-flex>
                                  <app-reference
                                    v-model="entity.unloadingAddress"
                                    class="mx-2 my-0"
                                    label="Адрес выгрузки*"
                                    :rules="[v => !!v || '']"
                                    resource="addresses"
                                    parent-filter="cityId"
                                    :parent-id="
                                      entity.unloadingCity
                                        ? entity.unloadingCity.id
                                        : 0
                                    "
                                    component-name="Address"
                                    :error="
                                      checkMissingElement(
                                        entity.unloadingAddress,
                                        true
                                      )
                                    "
                                    :scroll-value="scrollValue"
                                    @input="unloadingAddressChanged"
                                  />
                                </v-flex>
                              </v-layout>
                              <UnloadingContacts
                                :value="entity.contactsUnloading"
                                :scroll-value="scrollValue"
                                :entity-id="entity.id"
                                :is-our-warehouse="isOurWarehouse"
                                @change="unloadingContactsChanged"
                              />
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-layout>
                      <v-layout style="height:300px" />
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn
                small
                class="ma-0"
                color="primary"
                :disabled="!(isValid && isChanged)"
                :loading="loadingSubmit"
                @click="submit()"
              >
                Сохранить
              </v-btn>
              <v-btn
                small
                class="my-0 mx-2"
                :loading="loadingDraft"
                @click="submitDraft()"
              >
                Сохранить черновик
              </v-btn>
              <v-btn
                v-if="isDraft && id && id > 0"
                small
                class="my-0 mx-2"
                color="error"
                :loading="loadingDeleteDraft"
                @click="deleteDraft()"
              >
                Удалить безвозвратно
              </v-btn>
              <v-spacer />
              <v-btn
                small
                class="ma-0"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-card-actions>

            <v-card>
              <v-dialog
                v-model="attachmentDialog"
                persistent
                width="500"
              >
                <v-card>
                  <v-card-actions>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      Загруженные документы
                    </v-card-title>
                    <v-spacer />
                    <v-btn
                      small
                      style="height: 20px; width: 20px;"
                      fab
                      dark
                      color="grey"
                      icon
                      @click="attachmentDialog = false"
                    >
                      <v-icon
                        small
                        dark
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-text>
                    <Attachments
                      v-model="newAttachmentsForExport"
                      :block-height="100"
                      :resource="`shipping-drafts/${id}`"
                      :disabled-sender="true"
                      :draft-id="id"
                      :disabled-upload="true"
                      :disabled-download="false"
                      :disabled-upload-to-server="!isDraft"
                      :type="attachmentsType.EXPORT"
                      title="Экспортные документы"
                      @input="changeAttachmentsForExport"
                    />
                    <v-divider class="mt-2" />
                    <Attachments
                      v-model="newAttachments"
                      :block-height="100"
                      :resource="`shipping-drafts/${id}`"
                      :disabled-sender="true"
                      :disabled-upload="false"
                      :disabled-upload-to-server="!isDraft"
                      :disabled-download="true"
                      :draft-id="id"
                      title="Другие документы"
                      @input="changeAttachments"
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-card>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex
      xs1
      class="text-xs-center"
    >
      <v-btn
        text
        large
        icon
        @click="stepper = nextStep"
      >
        <v-icon
          x-large
          color="primary"
        >
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  unloadingPlaceTypes,
} from "../../../enums/unloading-place-types.js";
import {
  attachmentsType,
} from "../../../enums/attachments-types.js";
import SenderFactory from "./SenderFactory.vue";
import SenderWarehouse from "./SenderWarehouse.vue";
import SenderAddress from "./SenderAddress.vue";
import { shippingTypes } from "../shipping-types.js";
import LabelSelectSimple from "../../../components/LabelSelectSimple.vue";
import LabelSelectComplex from "../../../components/LabelSelectComplex.vue";
import Packages from "./Packages.vue";
import Attachments from "../../../components/attachments/Attachments.vue";
import mixin from "../../../mixins/confirm-cancel-changes.js";
import * as newShippingApi from "../../../backend/newShippingApi.js";
import CountryFilteringReference from "../../../components/references/CountryFilteringReference.vue";
import UnloadingContacts from "./UnloadingContacts.vue";
import auth from "../../../auth.js";
import * as draftsApi from "../../../backend/draftsApi.js";

export default {
  components: {
    SenderFactory,
    SenderWarehouse,
    SenderAddress,
    LabelSelectSimple,
    LabelSelectComplex,
    Packages,
    Attachments,
    CountryFilteringReference,
    UnloadingContacts,
  },
  mixins: [mixin],
  props: ["id", "isDraft"],
  data() {
    return {
      stepper: 1,
      stepTitles: [
        "Местонахождение",
        "Характеристики",
        "Доставка",
        "Итог",
      ],
      isValid: false,
      entity: {},
      isChanged: false,
      senderType: 0,
      senderTypeItems: [{
        value: unloadingPlaceTypes.FACTORY,
        text: "Склад продавца",
      },
      {
        value: unloadingPlaceTypes.GROUPEDWAREHOUSE,
        text: "Группажный склад",
      },
      {
        value: unloadingPlaceTypes.ADDRESS,
        text: "Другое",
      },
      {
        value: unloadingPlaceTypes.OTHERWAREHOUSE,
        text: "Другой склад",
      }],
      ukraine: null,
      isOurWarehouse: false,
      wantedDates: null,
      newAttachments: [],
      newAttachmentsForExport: [],
      scrollValue: 0,
      attachmentDialog: false,
      loadingSubmit: false,
      loadingDraft: false,
      loadingDeleteDraft: false,
    };
  },
  computed: {
    nextStep() {
      if (this.stepper == 1) {
        return 2;
      } else if (this.stepper == this.stepTitles.length) {
        return 1;
      } else {
        return this.stepper + 1;
      }
    },
    prevStep() {
      if (this.stepper == 1) {
        return 4;
      } else {
        return this.stepper - 1;
      }
    },
    blockHeight() {
      return window.innerHeight - 335;
    },
    unloadingPlaceTypes() {
      return unloadingPlaceTypes;
    },
    countryWithoutUkraine() {
      return (
        this.entity.shippingType &&
        (this.entity.shippingType.id === shippingTypes.ExportUkraine ||
          this.entity.shippingType.id === shippingTypes.OutsideUkraine)
      );
    },
    shippingTypes() {
      return shippingTypes;
    },
    minDate() {
      return this.moment(this.entity.readyDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    attachmentsType() {
      return attachmentsType;
    },
    disableUnloadingCountry() {
      return (
        this.entity.shippingType &&
        (this.entity.shippingType.id === shippingTypes.ImportUkraine ||
          this.entity.shippingType.id === shippingTypes.InsideUkraine)
      );
    },
  },
  created() {
    this.senderType = this.unloadingPlaceTypes?.FACTORY;
    if (this.id && this.id > 0) {
      this.getDraft();
    } else {
      this.getPreparedEntity();
    }
  },
  methods: {
    onScroll(e) {
      this.scrollValue = e.target.scrollTop;
    },
    handleEntityChange() {
      this.isChanged = this.isEntityReallyChanged(
        this.originEntity,
        this.entity,
      );
    },
    isEntityReallyChanged(origin, current) {
      if (!origin) {
        return true;
      } else {
        let isChanged = false;
        for (const property in origin) {
          if (typeof current[property] === "object") {
            isChanged =
              current[property] && origin[property]
                ? origin[property].id !== current[property].id
                : true;
          } else {
            isChanged =
              origin[property].toString() !== current[property].toString();
          }
          if (isChanged) {
            return true;
          }
        }
      }
      return false;
    },
    shippingTypeChanged() {
      this.handleEntityChange();

      if (
        this.entity.shippingType &&
        (this.entity.shippingType.id === shippingTypes.ImportUkraine ||
          this.entity.shippingType.id === shippingTypes.InsideUkraine)
      ) {
        this.$set(this.entity, "unloadingCountry", {
          id: this.ukraine.id,
          title: this.ukraine.title,
        });
      }

      if (
        this.countryWithoutUkraine &&
        this.entity.unloadingCountry &&
        this.entity.unloadingCountry.id === this.ukraine.id
      ) {
        this.$set(this.entity, "unloadingCountry", null);
      }
    },
    async getPreparedEntity() {
      const response = await newShippingApi.getNewShipping();
      const newEntity = {};
      for (const key in response) {
        if (key === "readyDate") {
          newEntity[key] = null;
        } else if (response[key]) {
          newEntity[key] = response[key].title
            ? { id: response[key].id, title: response[key].title }
            : response[key];
        } else if (key === "unloadingCountry") {
          this.getNewShippingUkr();
        } else {
          newEntity[key] = response[key];
        }
      }
      this.entity = newEntity;
      this.originEntity = JSON.parse(JSON.stringify(this.entity));
      this.unloadingAddressChanged();
      this.ukraine = await newShippingApi.getUkraine();
      this.entityLoaded = true;
    },
    async getNewShippingUkr() {
      const response = await newShippingApi.getNewShippingUkr();
      if (response) {
        this.$set(this.entity, "unloadingCountry", response);
      } else {
        this.$set(this.entity, "unloadingCountry", null);
      }
    },
    async unloadingAddressChanged() {
      if (!this.entity.unloadingAddress) {
        return;
      }
      const res = await newShippingApi.getWarehouseReferenceAddress(
        this.entity.unloadingAddress.id,
      );
      this.isOurWarehouse = res.data ? res.data.ourWarehouse : false;
    },
    unloadingCountryChanged() {
      this.entity.unloadingCity = "";
      this.entity.unloadingAddress = "";
    },
    unloadingContactsChanged(contactsUnloading) {
      this.entity.contactsUnloading = [];
      this.$set(this.entity, "contactsUnloading", contactsUnloading);
      this.handleEntityChange();
    },
    checkValidWantedDate() {
      if (!this.isAfterReadyDate) {
        return;
      }
      this.error = this.isAfterReadyDate === true ? false : true;
    },
    changePackages(value) {
      this.entity.packages = value;
      if (
        this.entity.packages.find(
          i => i.width > 2.4 || i.height > 2.4 || i.length > 2.4,
        )
      ) {
        this.entity.oversized = true;
      } else {
        this.entity.oversized = false;
      }
      this.handleEntityChange();
    },
    isAfterReadyDate() {
      if (this.wantedDates && this.wantedDates.dateFrom) {
        return this.moment(this.wantedDates.dateFrom).isAfter(
          this.entity.readyDate,
        );
      }
    },
    changeAttachmentsForExport(value) {
      if (this.isDraft) {
        return this.newAttachmentsForExport.concat(value);
      } else {
        this.newAttachmentsForExport.concat(
          value.forEach(a => {
            a.id = 0;
            a.title = a.sourceName;
          }),
        );
      }
    },
    changeAttachments(value) {
      if (this.isDraft) {
        return this.newAttachmentsForExport.concat(value);
      } else {
        this.newAttachments.concat(
          value.forEach(a => {
            a.id = 0;
            a.title = a.sourceName;
          }),
        );
      }
    },
    async submit() {
      this.loadingSubmit = true;
      this.isChanged = false;
      const user = auth.getUser();

      const entityForSaving = {
        shippingDraftId: this.id ? this.id : 0,
        clientId: user.clientId,
        authorContactPersonId: user.contactPersonId,
        attachments: this.newAttachmentsForExport
          .concat(this.newAttachments)
          .map(a => {
            return {
              id: a.id || 0,
              originName: a.title || a.originName,
              type: a.type,
              data: a.data,
            };
          }),
        cargoComment: this.entity.cargoComment,
        packages:
          this.entity.packages && this.entity.packages.length > 0
            ? this.entity.packages.map(p => {
              return {
                packageTypeId: p.packageType.id,
                quantity: p.quantity,
                length: p.length ? p.length : null,
                width: p.width ? p.width : null,
                height: p.height ? p.height : null,
              };
            })
            : [],
        clientAlias: this.entity.clientAlias,
        commentForShipping: this.entity.commentForShipping,
        countryId: this.entity.country.id,
        currencyId: this.entity.currency.id,
        destinationCountryId: this.entity.destinationCountry.id,
        internalRequestNumber: this.entity.internalRequestNumber,
        invoiceSum: this.entity.invoiceSum,
        isPayed: this.entity.isPayed,
        parseByOrders: this.entity.parseByOrders,
        loadingAddressId: this.entity.loadingAddress
          ? this.entity.loadingAddress.id
          : null,
        contactsLoading:
          this.entity.contactsLoading && this.entity.contactsLoading.length > 0
            ? this.entity.contactsLoading.map(c => {
              return {
                id: c.id,
                contactPersonId: c.contactPerson.id,
                contactPhone: c.contactPhone,
                contactEmail: c.contactEmail,
              };
            })
            : [],
        loadingNumber: this.entity.loadingNumber,
        loadingPlaceTimetable: this.entity.loadingPlaceTimetable,
        needExId: this.entity.needEx.id,
        oversized: this.entity.oversized,
        packingMethod: this.entity.packingMethod,
        readyDate: this.entity.readyDate,
        shippingTypeId: this.entity.shippingType.id,
        unloadingAddressId: this.entity.unloadingAddress.id,
        unloadingCityId: this.entity.unloadingCity.id,
        contactsUnloading:
          this.entity.contactsUnloading && this.entity.contactsUnloading.length
            ? this.entity.contactsUnloading.map(c => {
              return {
                id: c.id,
                contactPersonId: c.contactPerson.id,
                contactPhone: c.contactPhone,
              };
            })
            : [],
        unloadingCountryId: this.entity.unloadingCountry.id,
        urgency: this.entity.urgency,
        volume: this.entity.volume,
        weight: this.entity.weight,
        senderWarehouseId: this.entity.senderWarehouse
          ? this.entity.senderWarehouse.id
          : null,
        senderFactoryId: this.entity.senderFactory
          ? this.entity.senderFactory.id
          : null,
        senderAddressId: this.entity.senderAddress
          ? this.entity.senderAddress.id
          : null,
        productDescription: this.entity.productDescription,
        wantedDateFrom: this.wantedDates.dateFrom,
        wantedDateTo: this.wantedDates.dateTo
          ? this.wantedDates.dateTo
          : this.wantedDates.dateFrom,
        factoryClosingRequestDate: this.entity.factoryClosingDate ,
      };

      const res = await newShippingApi.createShipping(entityForSaving);

      if (this.id && this.id > 0) {
        await newShippingApi.deleteShippingDraft(this.id);
      }

      if (res.shippingId === 0) {
        this.showError("Ошибка при сохранении поставки");
        this.loadingSubmit = false;
        this.$router.push({ name: "shippings" });
        return;
      }

      if (!res.isShippingAttachmentCreated) {
        this.showError("Ошибка при сохранении документов");
        this.loadingSubmit = false;
        this.$router.push({ name: "shippings" });
        return;
      }

      this.loadingSubmit = false;
      this.showInfo("Поставка добавлена");
      this.$router.push({ name: "shippings" });
    },
    async submitDraft() {
      this.loadingDraft = true;
      const user = auth.getUser();

      const entityForSaving = {
        clientId: user.clientId,
        authorContactPersonId: user.contactPersonId,
        cargoComment: this.entity.cargoComment,
        packages:
          this.entity.packages && this.entity.packages.length
            ? this.entity.packages.map(p => {
              return {
                packageTypeId: p.packageType.id,
                quantity: p.quantity,
                length: p.length,
                width: p.width,
                height: p.height,
              };
            })
            : [],
        clientAlias: this.entity.clientAlias,
        commentForShipping: this.entity.commentForShipping,
        countryId: this.entity.country ? this.entity.country.id : null,
        currencyId: this.entity.currency ? this.entity.currency.id : null,
        destinationCountryId: this.entity.destinationCountry
          ? this.entity.destinationCountry.id
          : null,
        internalRequestNumber: this.entity.internalRequestNumber,
        invoiceSum: this.entity.invoiceSum,
        isPayed: this.entity.isPayed,
        parseByOrders: this.entity.parseByOrders,
        loadingAddressId: this.entity.loadingAddress
          ? this.entity.loadingAddress.id
          : null,
        contactsLoading:
          this.entity.contactsLoading && this.entity.contactsLoading.length
            ? this.entity.contactsLoading.map(c => {
              return {
                id: c.id,
                contactPersonId: c.contactPerson.id,
                contactPhone: c.contactPhone,
                contactEmail: c.contactEmail,
              };
            })
            : [],
        loadingNumber: this.entity.loadingNumber,
        loadingPlaceTimetable: this.entity.loadingPlaceTimetable,
        needExId: this.entity.needEx ? this.entity.needEx.id : null,
        oversized: this.entity.oversized,
        packingMethod: this.entity.packingMethod,
        readyDate: this.entity.readyDate,
        shippingTypeId: this.entity.shippingType.id,
        unloadingAddressId: this.entity.unloadingAddress
          ? this.entity.unloadingAddress.id
          : null,
        unloadingCityId: this.entity.unloadingCity
          ? this.entity.unloadingCity.id
          : null,
        contactsUnloading:
          this.entity.contactsUnloading && this.entity.contactsUnloading.length
            ? this.entity.contactsUnloading.map(c => {
              return {
                id: c.id,
                contactPersonId: c.contactPerson.id,
                contactPhone: c.contactPhone,
              };
            })
            : [],
        unloadingCountryId: this.entity.unloadingCountry
          ? this.entity.unloadingCountry.id
          : null,
        urgency: this.entity.urgency,
        volume: this.entity.volume,
        weight: this.entity.weight,
        senderType: this.senderType,
        senderWarehouseId: this.entity.senderWarehouse
          ? this.entity.senderWarehouse.id
          : null,
        senderFactoryId: this.entity.senderFactory
          ? this.entity.senderFactory.id
          : null,
        senderAddressId: this.entity.senderAddress
          ? this.entity.senderAddress.id
          : null,
        productDescription: this.entity.productDescription,
        wantedDateFrom: this.wantedDates ? this.wantedDates.dateFrom : null,
        wantedDateTo: this.wantedDates
          ? this.wantedDates.dateTo
            ? this.wantedDates.dateTo
            : this.wantedDates.dateFrom
          : null,
        factoryClosingRequestDate: this.entity.factoryClosingDate,
      };

      try {
        if (this.id) {
          await newShippingApi.updateShippingDraft(this.id, entityForSaving);
          if (
            this.newAttachmentsForExport.length ||
            this.newAttachments.length
          ) {
            await this.saveDraftsAttachments(this.id);
          }
        } else {
          const res = await newShippingApi.createShippingDraft(entityForSaving);
          if (
            this.newAttachmentsForExport.length ||
            this.newAttachments.length
          ) {
            await this.saveDraftsAttachments(res);
          }
        }
      } catch (err) {
        this.showError("Ошибка при сохранении черновика");
      } finally {
        this.isChanged = false;
        this.loadingDraft = false;
        this.showInfo("Черновик сохранен");
        this.$router.push({ name: "drafts" });
      }
    },
    async deleteDraft() {
      if (
        confirm(
          "Восстановление черновика невозможно. Вы действительно хотите удалить черновик?",
        )
      ) {
        this.loadingDeleteDraft = true;
        await newShippingApi.deleteShippingDraft(this.id);
        this.loadingDeleteDraft = false;
        this.showInfo("Черновик поставки удален");
        this.suppressLeaveConfirm = true;
        this.$router.go(-1);
      }
    },
    async saveDraftsAttachments(id) {
      const items = this.newAttachments
        .concat(this.newAttachmentsForExport)
        .map(a => {
          return {
            originName: a.title || a.originName,
            type: a.type,
            data: a.data,
          };
        });
      await newShippingApi.createDraftsAttachments(id, items);
    },
    cancel() {
      if (
        confirm(
          "Отменить изменения?",
        )
      ) {
        this.suppressLeaveConfirm = true;
        this.$router.go(-1);
      }

    },
    checkMissingElement(value, required) {
      if (!value && !required) {
        return "label-color";
      }
      if (!value && required) {
        return true;
      }
    },
    checkMissingVolume(value, required) {
      if (!value && !required) {
        return "label-color";
      }
      if (value == null && required) {
        return true;
      }
    },
    async getDraft() {
      const response = await draftsApi.getDraft(this.id);

      this.entity = response;

      this.newAttachments = response.attachments;
      this.newAttachmentsForExport = response.attachmentsForExport;

      if (this.entity.senderFactory) {
        const response = await newShippingApi.getFactory(this.entity.senderFactory.id);
        const item = response.loadingPlaces.find(
          i => i.address.id === this.entity.loadingAddress?.id,
        );
        if (item) {
          this.$set(this.entity, "loadingPlace", {
            id: item.id,
            title: item.address.title,
          });
        } else {
          this.$set(this.entity, "loadingPlace", null);
        }
      }
      this.senderType = response.senderType;
      this.originEntity = JSON.parse(JSON.stringify(this.entity));

      if (this.entity.wantedDateFrom) {
        this.wantedDates = {
          dateFrom: this.entity.wantedDateFrom,
          dateTo:
            this.entity.wantedDateTo &&
            this.entity.wantedDateTo != this.entity.wantedDateFrom
              ? this.entity.wantedDateTo
              : null,
        };
      } else {
        this.wantedDates = null;
      }
      this.ukraine = await newShippingApi.getUkraine();
      this.entityLoaded = true;
    },
  },
};
</script>
