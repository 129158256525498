<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :search-input.sync="search"
    :label="label"
    :rules="[v => !!v || '']"
    :disabled="readonly || disabled"
    :menu-props="menuPropsClose"
    :error="error"
    :loading="loading || loadingItems || loadingInfo"
    dense
    return-object
    hide-details
    no-filter
    no-data-text="У производителя не указаны места погрузки"
    outlined
    @change="handleItemChange"
    @click="closeMenuPropsOnClick(false)"
  >
    <template v-slot:append>
      <AddReferenceBtn @click="createNew" />
    </template>
  </v-autocomplete>
</template>
<script>
import AddReferenceBtn from "../../../components/AddReferenceBtn.vue";
import CloseDropdownList from "../../../mixins/close-dropdown-list.js";
import { NestedEditorsEventBus } from "../../../components/nested-editors/nested-editors-event-bus.js";
import {
  getFactoryLoadingPlace,
  getFactoryLoadingPlaceReference,
  searchFactoryLoadingPlace,
} from "../../../backend/newShippingApi.js";

export default {
  components: { AddReferenceBtn },
  mixins: [CloseDropdownList],
  props: [
    "value",
    "label",
    "disabled",
    "error",
    "factoryId",
    "scrollValue",
    "entity",
    "loadingItems",
  ],
  data() {
    return {
      selected: null,
      search: null,
      items: [],
      loading: false,
      resource: "loading-places",
      componentName: "LoadingPlaceModal",
      loadingInfo: false,
    };
  },
  computed: {
    readonly() {
      return this.factoryId ? false : true;
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.selected = null;
          this.search = null;
          this.items = [];
          return;
        }
        if (this.selected && this.value.id === this.selected.value) {
          return;
        }
        const selectedItem = this.items.find((i) => i.value === this.value.id);
        if (selectedItem) {
          this.selected = selectedItem;
        } else {
          this.setSelectedElement(this.value);
        }
      },
      deep: true,
    },
    search() {
      if (!this.search) {
        this.selected = null;
        this.fetchAll();
        return;
      }

      if (this.loading) {
        return;
      }

      if (this.selected && this.selected.text === this.search) {
        return;
      }

      this.loading = true;
      setTimeout(() => {
        if (!this.search) {
          this.selected = null;
          this.items = [];
          this.loading = false;
          return;
        }

        this.searchReference();
        this.loading = false;
      }, 500);
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      closeActivList(this.$children[0]);

      function closeActivList(value) {
        if (value.isActive) {
          value.isActive = false;
        }
        if (value.$children) {
          value.$children.forEach((v) => closeActivList(v));
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.setSelectedElement(this.value);
    }
    NestedEditorsEventBus.$on("create-" + this._uid, (createdId) => {
      if (!createdId) return;
      this.getReference(createdId);
      this.showInfo("Место погрузки добавлено");
      this.loading = false;
    });
  },
  mounted() {
    this.$watch(
      () => {
        return this.$children[0].$data.isMenuActive;
      },
      (newVal) => {
        if (newVal) {
          this.fetchAll();
        } else {
          this.items = [];
          if (this.selected) {
            this.items.push(this.selected);
          }
        }
      },
    );
  },
  methods: {
    handleItemChange() {
      this.$emit(
        "input",
        this.selected
          ? { id: this.selected.value, title: this.selected.text }
          : null,
      );
    },
    async fetchAll() {
      if (!this.factoryId) return;

      this.loadingInfo = true;

      this.items = await getFactoryLoadingPlace(this.factoryId);

      this.loadingInfo = false;
    },
    createNew() {
      this.closeMenuPropsOnClick(true);
      this.$emit("click");
      NestedEditorsEventBus.$emit("open", {
        title: this.label,
        uid: this._uid,
        component: this.componentName,
        params: { factoryId: this.factoryId },
      });
    },
    setSelectedElement(value) {
      const newItem = {
        text: value.title,
        value: value.id,
      };
      this.selected = newItem;
      this.items.push(this.selected);
      this.handleItemChange();
    },
    async getReference(id) {
      const response = await getFactoryLoadingPlaceReference(
        this.factoryId,
        id,
      );
      await this.setSelectedElement(response);
    },
    async searchReference() {
      this.items = await searchFactoryLoadingPlace(this.factoryId, this.search);
    },
  },
};
</script>
