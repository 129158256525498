<template>
  <v-layout column>
    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs6>
        <app-reference
          v-model="entity.country"
          :outlined="true"
          class="mx-2 my-0"
          label="Страна отправления*"
          :rules="[v => !!v || '']"
          resource="countries"
          component-name="Country"
          :error="checkMissingElement(entity.country, true)"
          :scroll-value="scrollValue"
          :disabled="disabled"
          @input="clearSender"
        />
      </v-flex>
      <v-flex xs6>
        <app-reference
          v-model="entity.senderWarehouse"
          :outline="true"
          class="mx-2 my-0"
          label="Склад*"
          resource="warehouses"
          component-name="Warehouse"
          parent-filter="countryId"
          :parent-id="entity.country ? entity.country.id : 0"
          filter-name="groupedWarehouse"
          :filter-value="senderWarehouseFilter"
          :without-parent-lock="true"
          :rules="[v => !!v || '']"
          :error="checkMissingElement(entity.senderWarehouse, true)"
          :scroll-value="scrollValue"
          @input="warehouseChanged"
        />
      </v-flex>
    </v-layout>

    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs12>
        <app-tiny-reference
          :id="clientId"
          ref="client-alias"
          v-model="entity.clientAlias"
          :outline="true"
          class="mx-2 my-0"
          label="Клиент по документам"
          resource="clients"
          info="aliases"
          :without-length-lock="true"
          :scroll-value="scrollValue"
          @input="handleEntityChange"
        >
          <template v-slot:append-content="slotProps">
            <v-layout align-end>
              <v-text-field
                v-model="newClientAlias"
                outlined
                class="px-1 pt-0 mt-0 text-field-size"
                placeholder="Добавить название"
                hide-details
                dense
              />
              <v-divider horisontal />
              <v-spacer />
              <v-btn
                small
                :disabled="newClientAlias.length < 1"
                class="ma-0 ml-0 mr-1 plus-button"
                @click="saveNewClientAlias(slotProps.items)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-layout>
          </template>
        </app-tiny-reference>
      </v-flex>
    </v-layout>
    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs12>
        <v-textarea
          slot="activator"
          v-model="entity.loadingNumber"
          outlined
          dense
          class="mx-2 my-0"
          label="Референс погрузки"
          hide-details
          rows="1"
          auto-grow
          @input="handleEntityChange"
        />
      </v-flex>
    </v-layout>

    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs6>
        <v-text-field
          :value="entity.loadingAddress ? entity.loadingAddress.title : ''"
          outlined
          class="mx-2 my-0"
          label="Адрес погрузки*"
          :rules="[v => !!v || '']"
          :disabled="disabled"
          hide-details
          dense
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="entity.loadingPlaceTimetable"
          outlined
          class="mx-2 my-0"
          label="Время работы места погрузки"
          hide-details
          dense
        />
      </v-flex>
    </v-layout>
    <LoadingContacts
      :value="contacts"
      :warehouse-id="entity.senderWarehouse ? entity.senderWarehouse.id : 0"
      :scroll-value="scrollValue"
      :entity-id="entity.id"
      sender-type="WAREHOUSE"
      @change="contactsLoadingChanged"
    />
  </v-layout>
</template>

<script>
import LoadingContacts from "./LoadingContacts.vue";
import * as newShippingsApi from "../../../backend/newShippingApi.js";
import auth from "../../../auth.js";

export default {
  components: {
    LoadingContacts,
  },
  props: [
    "entity",
    "isHighlightLabel",
    "scrollValue",
    "warehouseMode",
    "changedSenderType",
    "disabled",
  ],
  data() {
    return {
      newClientAlias: "",
      contacts: [],
    };
  },
  computed: {
    senderWarehouseFilter() {
      return this.warehouseMode === "groupedWarehouse" ? true : false;
    },
    clientId() {
      const user = auth.getUser();
      return user.clientId;
    },
  },
  watch: {
    warehouseMode() {
      this.clearSender();
    },
  },
  created() {
    if (this.entity.id && !this.changedSenderType) {
      this.contacts = this.entity.contactsLoading;
      return;
    }
    this.clearSender();
  },
  methods: {
    handleEntityChange() {
      this.$emit("handleEntityChange");
    },
    clearSender() {
      this.entity.loadingAddress = null;
      this.entity.senderWarehouse = null;
      this.entity.contactsLoading = [];
      this.entity.loadingPlaceTimetable = "";
      this.contacts = [];
    },
    async saveNewClientAlias(items) {
      await newShippingsApi.updateNewClientAlias(this.clientId, this.newClientAlias);
      this.entity.clientAlias = this.newClientAlias;
      items.push({ value: this.newClientAlias, text: this.newClientAlias });
      this.$refs["client-alias"].$children[0].blur();
      this.newClientAlias = "";
    },
    async warehouseChanged() {
      this.entity.loadingAddress = null;
      this.entity.contactsLoading = [];
      this.entity.loadingPlaceTimetable = null;

      if (!this.entity.senderWarehouse) {
        this.clearSender();
        return;
      }

      const response = await newShippingsApi.getWarehouse(
        this.entity.senderWarehouse.id,
      );
      if (response.country) {
        this.$set(this.entity, "country", {
          id: response.country.id,
          title: response.country.title,
        });
      }
      if (response.address) {
        this.$set(this.entity, "loadingAddress", {
          id: response.address.id,
          title: response.address.title,
        });
      }
      if (response.timetable) {
        this.$set(this.entity, "loadingPlaceTimetable", response.timetable);
      }
      if (response && response.contacts.length === 1) {
        this.$set(this.entity, "contactsLoading", [
          {
            id: 0,
            contactPerson: {
              id: response.contacts[0].id,
              title: response.contacts[0].fullName,
            },
            contactPhone: response.contacts[0].phones.length
              ? response.contacts[0].phones.map(p =>
                this.$options.filters.phoneInternational(p),
              ).join("; ")
              : "",
            contactEmail: response.contacts[0].emails.length
              ? response.contacts[0].emails.join("; ")
              : "",
          },
        ]);
      }
    },
    contactsLoadingChanged(contactDetails) {
      this.entity.contactsLoading = [];
      this.$set(this.entity, "contactsLoading", contactDetails);
      this.contacts = contactDetails;
      this.handleEntityChange();
    },
    checkMissingElement(value, required) {
      if (!this.isHighlightLabel) {
        return;
      }
      if (!value && !required) {
        return "label-color";
      }
      if (!value && required) {
        return true;
      }
    },
  },
};
</script>
