<template>
  <v-layout column>
    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs6>
        <app-reference
          v-model="entity.country"
          :outlined="true"
          class="mx-2 my-0"
          label="Страна отправления*"
          :rules="[v => !!v || '']"
          resource="countries"
          component-name="Country"
          :error="checkMissingElement(entity.country, true)"
          :scroll-value="scrollValue"
          :disabled="disabled"
          @input="clearSender"
        />
      </v-flex>
      <v-flex xs6>
        <app-reference
          ref="sender-addresses"
          v-model="entity.senderAddress"
          class="mx-2 my-0"
          label="Адрес погрузки*"
          :rules="[v => !!v || '']"
          resource="addresses"
          parent-filter="countryId"
          :parent-id="entity.country ? entity.country.id : 0"
          component-name="Address"
          :without-parent-lock="true"
          :height-field="44"
          :error="checkMissingElement(entity.senderAddress, true)"
          :scroll-value="scrollValue"
          :disabled="disabled"
          @input="addressChanged()"
        />
      </v-flex>
    </v-layout>

    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs12>
        <app-tiny-reference
          :id="clientId"
          ref="client-alias"
          v-model="entity.clientAlias"
          :outline="true"
          class="mx-2 my-0"
          label="Клиент по документам"
          resource="clients"
          info="aliases"
          :without-length-lock="true"
          :scroll-value="scrollValue"
          @input="handleEntityChange"
        >
          <template v-slot:append-content="slotProps">
            <v-layout align-end>
              <v-text-field
                v-model="newClientAlias"
                outlined
                class="px-1 pt-0 mt-0 text-field-size"
                placeholder="Добавить название"
                hide-details
                dense
              />
              <v-divider horisontal />
              <v-spacer />
              <v-btn
                small
                :disabled="newClientAlias.length < 1"
                class="ma-0 ml-0 mr-1 plus-button"
                @click="saveNewClientAlias(slotProps.items)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-layout>
          </template>
        </app-tiny-reference>
      </v-flex>
    </v-layout>
    <v-layout
      align-end
      class="my-2"
    >
      <v-flex xs12>
        <v-textarea
          slot="activator"
          v-model="entity.loadingNumber"
          outlined
          class="mx-2 my-0"
          hide-details
          rows="1"
          dense
          auto-grow
          label="Референс погрузки"
          @input="handleEntityChange"
        />
      </v-flex>
    </v-layout>
    <v-flex xs12>
      <v-text-field
        v-model="entity.loadingPlaceTimetable"
        outlined
        class="mx-2 my-0"
        label="Время работы места погрузки"
        hide-details
        dense
      />
    </v-flex>
    <v-flex xs12>
      <LoadingContacts
        :value="entity.contactsLoading"
        :scroll-value="scrollValue"
        :entity-id="entity.id"
        sender-type="ADDRESS"
        class="my-2"
        @change="contactsLoadingChanged"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import LoadingContacts from "./LoadingContacts.vue";
import * as newShippingApi from "../../../backend/newShippingApi.js";
import auth from "../../../auth.js";

export default {
  components: { LoadingContacts },
  props: [
    "entity",
    "fieldStyle",
    "isHighlightLabel",
    "scrollValue",
    "changedSenderType",
    "disabled",
  ],
  data() {
    return {
      newClientAlias: "",
    };
  },
  computed: {
    clientId() {
      const user = auth.getUser();
      return user.clientId;
    },
  },
  created() {
    if (this.entity.id && !this.changedSenderType) {
      return;
    }
    this.clearSender();
  },
  methods: {
    handleEntityChange() {
      this.$emit("handleEntityChange");
    },
    clearSender() {
      this.entity.loadingAddress = null;
      this.entity.senderAddress = null;
      this.entity.contactsLoading = [];
      this.entity.loadingPlaceTimetable = "";
    },
    async addressChanged() {
      this.entity.loadingAddress = null;
      if (!this.entity.senderAddress) {
        return;
      }

      this.$set(this.entity, "loadingAddress", {
        id: this.entity.senderAddress.id,
        title: this.entity.senderAddress.title,
      });

      const response = await newShippingApi.getAddress(this.entity.senderAddress.id);
      this.$set(this.entity, "country", {
        id: response.country.id,
        title: response.country.title,
      });
    },
    async saveNewClientAlias(items) {
      await newShippingApi.updateNewClientAlias(this.clientId, this.newClientAlias);
      this.entity.clientAlias = this.newClientAlias;
      items.push({ value: this.newClientAlias, text: this.newClientAlias });
      this.$refs["client-alias"].$children[0].blur();
      this.newClientAlias = "";
    },
    getMask(controlType) {
      switch (controlType) {
        case "phone":
          return "+##(###)###-##-##";
        case "number":
          return "##########";
        default:
          return "";
      }
    },
    checkMissingElement(value, required) {
      if (!this.isHighlightLabel) {
        return;
      }
      if (!value && !required) {
        return "label-color";
      }
      if (!value && required) {
        return true;
      }
    },
    contactsLoadingChanged(contactDetails) {
      this.entity.contactsLoading = [];
      this.$set(this.entity, "contactsLoading", contactDetails);
      this.handleEntityChange();
    },
  },
};
</script>
