<template>
  <v-select
    v-model="selected"
    menu-props="offsetY"
    :items="items"
    :label="title"
    :background-color="backgroundColor"
    height="25"
    hide-details
    hide-selected
    :readonly="readonly"
    @change="change"
  >
    <template v-slot:selection="{ item }">
      <span class="pl-1">{{ item.text }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  props: [
    "title",
    "value",
    "trueTitle",
    "falseTitle",
    "trueColor",
    "falseColor",
    "readonly",
    "scrollValue",
  ],
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    backgroundColor() {
      const color = this.selected
        ? this.trueColor || "green"
        : this.falseColor || "red";
      return color + " lighten-4";
    },
    items() {
      return [
        { text: this.falseTitle || "Нет", value: false },
        { text: this.trueTitle || "Да", value: true },
      ];
    },
  },
  watch: {
    value: {
      handler(val) {
        this.selected = val;
      },
      immediate: true,
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      closeActivList(this.$children[0]);

      function closeActivList(value) {
        if (value.isActive) {
          value.isActive = false;
        }
        if (value.$children) {
          value.$children.forEach((v) => closeActivList(v));
        }
      }
    },
  },
  methods: {
    change() {
      this.$emit("input", this.selected);
    },
  },
};
</script>
