<template>
  <v-layout column>
    <template v-if="!contactDetails.length">
      <v-layout
        align-center
        justify-start
      >
        <v-text-field
          value=""
          label="Контактные данные погрузки"
          readonly
          dense
          hide-details
          outlined
          class="mx-2 my-0"
        />
        <v-btn
          icon
          small
          class="ma-0"
          @click="openEditor(false, 0)"
        >
          <v-icon color="grey">
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-layout>
    </template>
    <template v-for="(contact, index) in contactDetails">
      <v-layout
        :key="index"
        align-center
        justify-start
      >
        <v-text-field
          :key="index"
          :value="contact.title"
          label="Контактные данные погрузки"
          readonly
          dense
          hide-details
          outlined
          class="ma-2"
        />
        <MenuRowActions
          :list="rowActions"
          :value="contact"
          :index="index"
          @add="add(contactDetails.length)"
          @edit="openEditor(contact, index)"
          @remove="remove(index)"
        />
      </v-layout>
    </template>

    <v-dialog
      :value="showEditor"
      persistent
      width="1000"
    >
      <v-card>
        <v-card-title class="pb-1">
          <span class="title">Редактирование контактных данных</span>
        </v-card-title>
        <v-card-text>
          <v-layout>
            <v-flex xs4>
              <FactoryContactPersonSelect
                v-if="senderType === 'FACTORY'"
                v-model="contactDetail.contactPerson"
                :factory-id="senderFactory ? senderFactory.id : 0"
                :loading-place-id="loadingPlace ? loadingPlace.id : 0"

                :scroll-value="scrollValue"
                outlined
                class="mx-2 my-0"
                @input="changeContactPerson"
              />
              <WarehouseContactPersonSelect
                v-if="senderType === 'WAREHOUSE'"
                v-model="contactDetail.contactPerson"
                :warehouse-id="warehouseId ? warehouseId : 0"

                :scroll-value="scrollValue"
                outlined
                class="mx-2 my-0"
                @input="changeContactPerson"
              />
              <app-reference
                v-if="senderType === 'ADDRESS'"
                v-model="contactDetail.contactPerson"
                :outline="true"
                class="mx-2 my-0"
                label="Контактное лицо погрузки"
                resource="contact-persons"
                component-name="ContactPerson"
                :without-parent-lock="true"
                :scroll-value="scrollValue"
                @input="changeContactPerson"
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="contactDetail.contactPhone"
                outlined
                dense
                class="mx-2 my-0"
                label="Контактный номер телефона"
                hide-details
              />
            </v-flex>
            <v-flex xs4>
              <v-text-field
                v-model="contactDetail.contactEmail"
                outlined
                dense
                class="mx-2 my-0"
                label="Контактный e-mail"
                data-vv-name="contactDetail.contactEmail"
                hide-details
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            color="primary"
            @click="setContactDetails"
          >
            Ок
          </v-btn>
          <v-btn
            small
            @click="closeEditor"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import FactoryContactPersonSelect from "./FactoryContactPersonSelect.vue";
import WarehouseContactPersonSelect from "./WarehouseContactPersonSelect.vue";
import MenuRowActions from "../../../components/MenuRowActions.vue";
import { getLoadingPersonContact } from "../../../backend/newShippingApi.js";
export default {
  components: {
    FactoryContactPersonSelect,
    WarehouseContactPersonSelect,
    MenuRowActions,
  },
  props: [
    "value",
    "senderFactory",
    "loadingPlace",
    "scrollValue",
    "entityId",
    "warehouseId",
    "senderType",
  ],
  data() {
    return {
      contactDetails: [],
      contactDetail: null,
      showEditor: false,
      waitData: false,
    };
  },
  computed: {
    isDisabled() {
      return !this.contactDetails[this.contactDetails.length - 1]?.contactPerson
        ?.id;
    },
    rowActions() {
      if (!this.entityId) {
        return [
          {
            title: "Добавить",
            name: "add",
            action: this.add,
            type: "icon",
            icon: "mdi-plus",
            disabled: this.isDisabled,
          },
          {
            title: "Изменить",
            name: "edit",
            action: this.openEditor,
            type: "icon",
            icon: "mdi-pencil",
            disabled: false,
          },
          {
            title: "Удалить",
            name: "remove",
            action: this.remove,
            type: "icon",
            icon: "mdi-delete",
            disabled: false,
          },
        ];
      } else {
        return [
          {
            title: "Изменить",
            name: "edit",
            action: this.openEditor,
            type: "icon",
            icon: "mdi-pencil",
            disabled: false,
          },
          {
            title: "Удалить",
            name: "remove",
            action: this.remove,
            type: "icon",
            icon: "mdi-delete",
            disabled: false,
          },
        ];
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (!val) {
          this.contactsDetails = [];
          this.contactDetail = {};
          return;
        }
        this.contactDetails = val.map(v => {
          const phone = v.contactPhone
            ? this.$options.filters.phoneInternational(v.contactPhone)
            : "";

          const textDetail = v.contactPerson
            ? v.contactPhone && v.contactEmail
              ? `${v.contactPerson.title} / ${phone} / ${v.contactEmail}`
              : v.contactPhone && !v.contactEmail
                ? `${v.contactPerson.title} / ${phone}`
                : `${v.contactPerson.title} / ${v.contactEmail}`
            : "";

          return {
            id: v.id,
            title: textDetail,
            contactPerson: v.contactPerson,
            contactPhone: v.contactPhone,
            contactEmail: v.contactEmail,
          };
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.contactDetail = this.setContactDetail(false, 0);
    if (this.senderType === "FACTORY" && this.entityId && this.value.length) {
      this.waitData = true;
    }
  },
  methods: {
    async changeContactPerson(value) {
      if (!value) {
        this.contactDetail = this.setContactDetail(
          false,
          this.contactDetail.index,
        );
        return;
      }
      if (this.waitData) {
        return (this.waitData = false);
      }

      const response = await getLoadingPersonContact(value.id);
      if (!response) {
        return;
      }

      this.contactDetail = {
        id: this.contactDetail.id,
        title: "",
        contactPerson: { id: response.id, title: response.fullName },
        contactPhone: response.phones.length
          ? response.phones.map(p =>
            this.$options.filters.phoneInternational(p),
          ).join("; ")
          : "",
        contactEmail: response.emails.length ? response.emails.join("; ") : "",
        index: this.contactDetail.index,
      };
    },
    setContactDetail(value, index) {
      if (!value) {
        return {
          contactPerson: null,
          contactPhone: "",
          contactEmail: "",
          title: "",
          id: 0,
          index,
        };
      }
      return {
        contactPerson: value.contactPerson,
        contactPhone: value.contactPhone,
        contactEmail: value.contactEmail,
        title: value.title,
        id: value.id,
        index,
      };
    },
    add(index) {
      this.contactDetail = this.setContactDetail(false, index);
      this.contactDetails.push(this.contactDetail);
      this.openEditor(this.contactDetail, index);
    },
    remove(index) {
      this.contactDetails.splice(index, 1);
      this.$emit("change", this.contactDetails);
    },
    setContactDetails() {
      this.showEditor = false;
      if (this.contactDetails.length) {
        this.contactDetails.forEach((c, index) => {
          if (index === this.contactDetail.index) {
            c.contactPerson = this.contactDetail.contactPerson;
            c.contactPhone = this.contactDetail.contactPhone;
            c.contactEmail = this.contactDetail.contactEmail;
          }
        });
      } else {
        this.contactDetails.push(this.contactDetail);
      }
      this.$emit("change", this.contactDetails);
    },
    openEditor(contact, index) {
      if (contact) {
        this.contactDetail = this.setContactDetail(contact, index);
      } else {
        this.contactDetail = this.setContactDetail(false, index);
      }
      this.showEditor = true;
    },
    closeEditor() {
      this.showEditor = false;
      this.contactDetail = this.setContactDetail(false, 0);
    },
  },
};
</script>
